import {
  LoaderActions,
  SetGlobalLoaderVisibleAction,
  setReloadRefreshButtonVisibleAction,
} from '../reducers/loaderReducer'

export const setReloadRefresh = (
  value: boolean,
  isDashboard: boolean
): setReloadRefreshButtonVisibleAction => ({
  type: LoaderActions.setReloadRefreshButtonVisible,
  payload: { value, isDashboard },
})

export const setGlobalLoader = (value: boolean): SetGlobalLoaderVisibleAction => ({
  type: LoaderActions.setGlobalLoaderVisible,
  payload: { value },
})
