import { ThunkAction } from 'redux-thunk'

import { ApiError } from '../helpers/ProxyApiFetch/types'
import { Alert, AlertBody, LastNotification, Notification } from '../model'
import { State } from '../reducers'
import {
  AddLastNotificationAction,
  AddNotificationAction,
  DeleteAlertAction,
  FailFetchAlertsAction,
  FailFetchNotificationsAction,
  NotificationsAction,
  NotificationsActionTypes,
  SetAlertsAction,
  SetNotificationsAction,
  StartAddAlertAction,
  StartFetchAlertsAction,
  StartFetchNotificationsAction,
  SuccessAddAlertAction,
  SuccessFetchAlertsAction,
  SuccessFetchNotificationsAction,
} from '../reducers/notificationsReducer'
import apiClient from '../services/ApiClient'

export const startFetchAlerts = (): StartFetchAlertsAction => ({
  type: NotificationsActionTypes.startFetchAlerts,
})

export const failFetchAlerts = (errorMessage?: ApiError): FailFetchAlertsAction => ({
  type: NotificationsActionTypes.failFetchAlerts,
  payload: errorMessage,
})

export const successFetchAlerts = (): SuccessFetchAlertsAction => ({
  type: NotificationsActionTypes.successFetchAlerts,
})

export const setAlerts = (alerts: Alert[]): SetAlertsAction => ({
  type: NotificationsActionTypes.setAlerts,
  payload: alerts,
})

export const startAddAlert = (): StartAddAlertAction => ({
  type: NotificationsActionTypes.startAddAlert,
})

export const successAddAlert = (): SuccessAddAlertAction => ({
  type: NotificationsActionTypes.successAddAlert,
})

export const deleteAlertAction = (alertId: string): DeleteAlertAction => ({
  type: NotificationsActionTypes.deleteAlert,
  payload: alertId,
})

export const startFetchNotifications = (): StartFetchNotificationsAction => ({
  type: NotificationsActionTypes.startFetchNotifications,
})

export const failFetchNotifications = (errorMessage?: ApiError): FailFetchNotificationsAction => ({
  type: NotificationsActionTypes.failFetchNotifications,
  payload: errorMessage,
})

export const successFetchNotifications = (): SuccessFetchNotificationsAction => ({
  type: NotificationsActionTypes.successFetchNotifications,
})

export const setNotifications = (
  notifications?: Notification[],
  last?: LastNotification
): SetNotificationsAction => ({
  type: NotificationsActionTypes.setNotifications,
  payload: {
    notifications,
    last,
  },
})

export const addAlert =
  (alert: AlertBody): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    dispatch(startFetchAlerts())

    const response = await apiClient.alerts.add(alert)

    if (response.error) {
      dispatch(failFetchAlerts(response.error))
    }

    if (response?.responseData?.id) {
      dispatch(successAddAlert())
      dispatch(fetchAlerts())
    }
  }

export const updateAlert =
  (id: string, alert: AlertBody): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    dispatch(startFetchAlerts())

    const response = await apiClient.alerts.update(id, { ...alert, kind: undefined })

    if (response.error) {
      dispatch(failFetchAlerts(response.error))
    } else {
      dispatch(successAddAlert())
      dispatch(fetchAlerts())
    }
  }

export const deleteAlert =
  (id: string): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    const response = await apiClient.alerts.delete(id)

    if (!response.error) {
      dispatch(deleteAlertAction(id))
    }
  }

export const fetchAlerts =
  (): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    dispatch(startFetchAlerts())

    const response = await apiClient.alerts.getAll()

    if (response.error) {
      dispatch(failFetchAlerts(response.error))
    }

    if (response?.responseData?.data) {
      dispatch(successFetchAlerts())
      dispatch(setAlerts(response?.responseData?.data))
    }
  }

export const fetchNotifications =
  (): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    dispatch(startFetchNotifications())

    const response = await apiClient.notifications.getAll()

    if (response.error) {
      dispatch(failFetchNotifications(response.error))
    }

    if (response?.responseData?.notifications) {
      dispatch(successFetchNotifications())
      dispatch(
        setNotifications(response?.responseData?.notifications, response?.responseData?.last)
      )
    }
  }

export const markNotifications =
  (
    notifications: Notification[]
  ): ThunkAction<Promise<void>, State, unknown, NotificationsAction> =>
  async (dispatch): Promise<void> => {
    if (notifications.length === 0) {
      return
    }

    const lastNotification = notifications[0]
    const mark = { notification_id: lastNotification.id, created_at: lastNotification.created_at }
    const response = await apiClient.notifications.mark(mark)

    if (!response.error) {
      // do not send notifications from param, they can be stale. Let the state be conserved
      dispatch(setNotifications(undefined, response.responseData))
    }
  }

export const addNotification = (notification: Notification): AddNotificationAction => ({
  type: NotificationsActionTypes.addNotification,
  payload: notification,
})

export const addLastNotification = (
  lastNotification: LastNotification
): AddLastNotificationAction => ({
  type: NotificationsActionTypes.addLastNotification,
  payload: lastNotification,
})
