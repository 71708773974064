import { ThunkAction } from 'redux-thunk'

import { defaultSettings } from '../config/settings'
import { ApiError } from '../helpers/ProxyApiFetch/types'
import { ComplexSettings, Settings } from '../model'
import { State } from '../reducers'
import { AuthAction } from '../reducers/isAuth'
import {
  FailFetchSettingsAction,
  FailUpdateSettingsAction,
  SetSettingsAction,
  SettingsAction,
  SettingsActionTypes,
  StartFetchSettingsAction,
  StartUpdateSettingsAction,
  SuccessFetchSettingsAction,
  SuccessUpdateSettingsAction,
} from '../reducers/settingsReducer'
import { SetFavoritesAction } from '../reducers/tokens'
import apiClient from '../services/ApiClient'
import {
  addUniqueFavoritesToLocalStorage,
  filterBySettingsFieldsWhitelist,
  getFavoritesFromLocalStorage,
  getSettingsFromLocalStorage,
  setSettingsToLocalStorage,
} from '../services/preferencesService'
import { diffsBtwTokenIdLists } from '../services/tokenService'
import { setIsAuth } from '.'
import { addFavouriteList, setFavorites } from './tokenActions'

export const setSettings = (applicationsSettings: Settings): SetSettingsAction => ({
  type: SettingsActionTypes.setSettings,
  payload: { ...applicationsSettings },
})

export const initSettings =
  (isAuth: boolean): ThunkAction<void, State, unknown, SettingsAction | SetFavoritesAction> =>
  (dispatch): void => {
    if (isAuth) {
      dispatch(fetchSettings())
      return
    }

    // !isAuth

    const applicationsSettings = getSettingsFromLocalStorage()
    if (applicationsSettings) {
      dispatch(setSettings(applicationsSettings))
    }

    // clear favorites
    dispatch(setFavorites([]))

    // application: otherwise use settings reducer default state //
    // favorites: otherwise use tokens reducer default state
  }

export const startFetchSettings = (): StartFetchSettingsAction => ({
  type: SettingsActionTypes.startFetchSettings,
})

export const failFetchSettings = (errorMessage?: ApiError): FailFetchSettingsAction => ({
  type: SettingsActionTypes.failFetchSettings,
  payload: errorMessage,
})

export const successFetchSettings = (settings: Settings): SuccessFetchSettingsAction => ({
  type: SettingsActionTypes.successFetchSettings,
  payload: { settings: settings },
})

export const fetchSettings =
  (): ThunkAction<
    Promise<void>,
    State,
    unknown,
    SettingsAction | SetFavoritesAction | AuthAction
  > =>
  async (dispatch, getState): Promise<void> => {
    dispatch(startFetchSettings())

    const response = await apiClient.settings.load() // can be 404 - should create

    if (response.error) {
      dispatch(failFetchSettings(response.error))
    }

    // if 422: Signature verification failed
    if (response.error?.status === 422) {
      dispatch(setIsAuth(false))
    }
    // if !settings two cases: shouldCreate, or error while fetching(settings.isLoaded === false).
    const shouldCreateUserSettings = response.error?.status === 404
    if (shouldCreateUserSettings) {
      const applicationStateSettings = getState().settings.settingsData // dublicate in updateSettings
      const applicationLS = getSettingsFromLocalStorage()
      const applicationSettings = { ...applicationStateSettings, ...applicationLS }
      dispatch(updateSettings(applicationSettings, true)) // force true
    }

    const settings = response?.responseData?.settings
    if (settings?.application) {
      // set settings getted
      dispatch(successFetchSettings(settings?.application))
    } else {
      const applicationLS = getSettingsFromLocalStorage()
      if (applicationLS) {
        dispatch(setSettings(applicationLS))
      }
      // application: otherwise use settings reducer default state
    }

    const favoritesLS = getFavoritesFromLocalStorage()

    if (settings?.favorites) {
      if (favoritesLS?.length) {
        const diffsTokenIdList = diffsBtwTokenIdLists(favoritesLS, settings?.favorites)
        if (diffsTokenIdList.length) {
          dispatch(addFavouriteList(diffsTokenIdList))
        }
      }
      dispatch(setFavorites(settings?.favorites))
      addUniqueFavoritesToLocalStorage(settings?.favorites)
    } else {
      if (favoritesLS?.length) {
        dispatch(addFavouriteList(favoritesLS))
        dispatch(setFavorites(favoritesLS))
      }
      // favorites: otherwise use tokens reducer default state
    }
  }

export const failUpdateFetching = (error: ApiError): FailUpdateSettingsAction => ({
  type: SettingsActionTypes.failUpdateSettings,
  payload: error,
})

export const startUpdateSettings = (): StartUpdateSettingsAction => ({
  type: SettingsActionTypes.startUpdateSettings,
})

export const successUpdateSettings = (
  changedSettings: ComplexSettings
): SuccessUpdateSettingsAction => ({
  type: SettingsActionTypes.successUpdateSettings,
  payload: changedSettings,
})

export const updateSettings =
  (
    settingsData: Partial<Settings>,
    force = false
  ): ThunkAction<Promise<void>, State, unknown, SettingsAction> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { settings } = getState()

      dispatch(startUpdateSettings())

      const newApplicationSettings = filterBySettingsFieldsWhitelist({
        ...settings.settingsData,
        ...settingsData,
      }) as Settings
      setSettingsToLocalStorage(newApplicationSettings)
      dispatch(setSettings(newApplicationSettings))

      if (!force && !settings.isLoaded) {
        dispatch(failUpdateFetching({ message: 'settings are not loaded' }))
        return
      }

      const response = await apiClient.settings.update({
        application: newApplicationSettings,
      })

      if (response.error) {
        dispatch(failUpdateFetching({ message: `putSettings error: ${response.error?.message}` }))
        return
      }

      if (response.responseData?.settings) {
        dispatch(successUpdateSettings(response.responseData?.settings))
      } else {
        throw new Error('no settings in response')
      }
    } catch (error) {
      dispatch(
        failUpdateFetching({ message: `updateSettings:other_error: ${(error as Error).message}` })
      )
    }
  }

export const deleteUserSettings = async (): Promise<void> => {
  await apiClient.settings.delete()
}

export const resetFavorites = async (): Promise<void> => {
  await apiClient.settings.update({ application: defaultSettings, favorites: [] })
}
